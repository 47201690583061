'use client';

import { useCallback } from 'react';
import { useRouter } from 'next/navigation';

import If from '~/core/ui/If';
import Trans from '~/core/ui/Trans';
import OAuthProviders from '~/app/auth/components/OAuthProviders';

import EmailPasswordSignInContainer from '~/app/auth/components/EmailPasswordSignInContainer';
import PhoneNumberSignInContainer from '~/app/auth/components/PhoneNumberSignInContainer';
import EmailLinkAuth from '~/app/auth/components/EmailLinkAuth';
import { QueryClientProvider } from '@portal/query';

import configuration from '~/configuration';

function SignInMethodsContainer() {
  const router = useRouter();

  const onSignIn = useCallback(() => {
    router.push(configuration.paths.appHome);
  }, [router]);

  return (
    <QueryClientProvider>
      <OAuthProviders />

      <If condition={configuration.auth.providers.emailPassword}>
        <div className="flex w-full items-center gap-2">
          <div className="h-px flex-1 bg-[#ececec]" />
          <div className={'text-xs font-normal text-brand-primary-black/50'}>
            <Trans i18nKey={'auth:orContinueWithEmail'} />
          </div>
          <div className="h-px flex-1 bg-[#ececec]" />
        </div>

        <EmailPasswordSignInContainer onSignIn={onSignIn} />
      </If>

      <If condition={configuration.auth.providers.phoneNumber}>
        <PhoneNumberSignInContainer onSignIn={onSignIn} />
      </If>

      <If condition={configuration.auth.providers.emailLink}>
        <EmailLinkAuth />
      </If>
    </QueryClientProvider>
  );
}

export default SignInMethodsContainer;
