'use client';

import { useCallback, useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import useSignInWithEmailPassword from '~/core/hooks/use-sign-in-with-email-password';
import AuthErrorMessage from '~/app/auth/components/AuthErrorMessage';
import EmailPasswordSignInForm from '~/app/auth/components/EmailPasswordSignInForm';

const MAX_ATTEMPTS = 3; // 3 attempts before lockout
const LOCKOUT_TIME = 300000; // 5 minutes in milliseconds

const EmailPasswordSignInContainer: React.FCC<{
  onSignIn: (userId?: string) => unknown;
}> = ({ onSignIn }) => {
  const [attempts, setAttempts] = useState<number>(0);
  const [deviceAttempts, setDeviceAttempts] = useState<number>(0);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [lockoutExpiration, setLockoutExpiration] = useState<number | null>(null);
  const [currentEmail, setCurrentEmail] = useState<string | null>(null);
  const signInMutation = useSignInWithEmailPassword();
  const isLoading = signInMutation.isMutating;

  useEffect(() => {
    const storedDeviceAttempts = parseInt(localStorage.getItem('deviceLoginAttempts') || '0', 10);
    const storedAttempts = currentEmail
      ? parseInt(localStorage.getItem(`loginAttempts_${currentEmail}`) || '0', 10)
      : 0;
    const lockoutUntil = currentEmail ? parseInt(localStorage.getItem(`lockoutUntil_${currentEmail}`) || '0', 10) : 0;
    const currentTime = new Date().getTime();

    setDeviceAttempts(storedDeviceAttempts);
    setAttempts(storedAttempts);

    if (lockoutUntil && currentEmail && currentTime < lockoutUntil) {
      setIsLocked(true);
      setLockoutExpiration(lockoutUntil);
      toast.error('You have been locked out due to too many failed login attempts. Please try again later.', {
        style: { fontSize: '12px' },
      });
    } else {
      resetLockout(currentEmail); // Reset the lockout if time has passed
    }
  }, [currentEmail]);

  const resetLockout = (email: string | null) => {
    setIsLocked(false);
    setAttempts(0);
    setLockoutExpiration(null);
    if (email) {
      localStorage.removeItem(`loginAttempts_${email}`);
      localStorage.removeItem(`lockoutUntil_${email}`);
    }
  };

  const onSubmit = useCallback(
    async (credentials: { email: string; password: string }) => {
      setCurrentEmail(credentials.email.toLowerCase());

      if (isLocked) {
        const currentTime = new Date().getTime();
        if (lockoutExpiration && currentTime >= lockoutExpiration) {
          resetLockout(credentials.email); // Automatically unlock after time expires
        } else {
          return; // Prevent login attempts if still locked
        }
      }

      try {
        const data = await signInMutation.trigger(credentials);
        const userId = data?.user?.id;

        resetLockout(credentials.email); // Reset on successful login
        localStorage.setItem('deviceLoginAttempts', '0'); // Reset device attempts
        onSignIn(userId);
      } catch (e) {
        const newAttempts = attempts + 1;
        const newDeviceAttempts = deviceAttempts + 1;
        setAttempts(newAttempts);
        setDeviceAttempts(newDeviceAttempts);
        localStorage.setItem(`loginAttempts_${credentials.email}`, newAttempts.toString());
        localStorage.setItem('deviceLoginAttempts', newDeviceAttempts.toString());

        if (newAttempts >= MAX_ATTEMPTS || newDeviceAttempts >= MAX_ATTEMPTS) {
          setIsLocked(true);
          const lockoutUntil = new Date().getTime() + LOCKOUT_TIME;
          localStorage.setItem(`lockoutUntil_${credentials.email}`, lockoutUntil.toString());
          setLockoutExpiration(lockoutUntil);
          toast.error('You have been locked out due to too many failed login attempts. Please try again later.', {
            style: { fontSize: '12px' },
          });
        } else {
          toast.error(`You have ${MAX_ATTEMPTS - newAttempts} login attempts left.`, {
            style: { fontSize: '12px' },
          });
        }
      }
    },
    [onSignIn, signInMutation, attempts, deviceAttempts, isLocked, lockoutExpiration],
  );

  return (
    <>
      <Toaster position="top-right" />
      <AuthErrorMessage error={signInMutation.error} />
      <EmailPasswordSignInForm onSubmit={onSubmit} loading={isLoading} />
    </>
  );
};

export default EmailPasswordSignInContainer;
