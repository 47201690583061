'use client';

import Trans from '~/core/ui/Trans';
import { useForm } from 'react-hook-form';
import Link from 'next/link';

import TextField from '~/core/ui/TextField';
import Button from '~/core/ui/Button';
import If from '~/core/ui/If';
import { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

const EmailPasswordSignInForm: React.FCC<{
  onSubmit: (params: { email: string; password: string }) => unknown;
  loading: boolean;
}> = ({ onSubmit, loading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const emailControl = register('email', {
    required: true,
    onChange: (e) => {
      // Ensure email sent in lowercase
      setValue('email', e.target.value.toLowerCase(), { shouldValidate: true });
    },
  });
  const passwordControl = register('password', { required: true });
  const [isPasswordShown, setPasswordShown] = useState(false);
  const toggleEyeOff = () => {
    setPasswordShown(!isPasswordShown);
  };
  return (
    <form className={'w-full'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex-col space-y-4'}>
        <TextField>
          <TextField.Label>
            <Trans i18nKey={'common:email'} />

            <TextField.Input data-cy={'email-input'} required type="email" placeholder={''} {...emailControl} />
          </TextField.Label>
        </TextField>

        <TextField>
          <TextField.Label>
            <Trans i18nKey={'common:password'} />
            <TextField.Input
              required
              data-cy={'password-input'}
              type={isPasswordShown ? 'text' : 'password'}
              placeholder={''}
              {...passwordControl}
              iconsRight={() => (
                <>
                  {isPasswordShown ? (
                    <EyeOff className="size-5 cursor-pointer text-brand-primary-black/50" onClick={toggleEyeOff} />
                  ) : (
                    <Eye className="size-5 cursor-pointer text-brand-primary-black/50" onClick={toggleEyeOff} />
                  )}
                </>
              )}
            />

            <div className={'py-0.5 text-xs'}>
              <Link href={'/auth/password-reset'} className={'text-brand-email-password-sign-in-link hover:underline'}>
                <Trans i18nKey={'auth:passwordForgottenQuestion'} />
              </Link>
            </div>
          </TextField.Label>
        </TextField>

        <div>
          <Button className={'w-full'} color={'purple'} data-cy="auth-submit-button" type="submit" loading={loading}>
            <If condition={loading} fallback={<Trans i18nKey={'auth:signIn'} />}>
              <Trans i18nKey={'auth:signingIn'} />
            </If>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EmailPasswordSignInForm;
